import Component from "../../../../js/assets/component.js"

class SubMenuPC extends Component {
  constructor(el){
    super(el)
  }

  activate (){
    this.$el.addClass("-active")
  }

  inActivate (){
    this.$el.removeClass("-active")
  }

  showSubMenu(index){
    if(index===undefined||index===null){
      this.inActivate()
      for(let i=0; i<this.$subMenuContainer.length; i++){
        this.$subMenuContainer[i].removeClass("-active")
      }
    }

    if(index!==undefined&&index!==null){
      for(let i=0; i<this.$subMenuContainer.length; i++){
        if(i===index){
          this.$subMenuContainer[i].addClass("-active")
        }else{
          this.$subMenuContainer[i].removeClass("-active")
        }
      }
      this.setBgHeight()
      this.activate()
    }
  }

  setBgHeight(){
    this.$bg.css({
      "height": this.$el.height()
    })
  }

  mounted() {
    var self = this

    this.$bg = this.$el.find("> span.bg")
    this.$subMenuContainer = [];
    this.$el.find(".subMenuContainer").each(function(i){
      self.$subMenuContainer[i] = $(this)
    })
  }
}

const instance = new SubMenuPC("#menu .subMenu")
export default instance
