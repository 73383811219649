import Component from '../../../../js/assets/component.js'
import SearchForm from './search_form.js'

class SearchBox extends Component {
  constructor(selector) {
    //set root element
    super(selector)
  }

  // define data
  data() {
    return {
      "active": false
    }
  }

  // action at data changed
  changed() {
    return {
      "active": (val, prev) => {
        this.switchState(val, prev)
      }
    }
  }

  switchState(state, prev) {
    if(state === true){
      this.$el.addClass("-active")
      this.$buttonClose.addClass("-active")
      this.$buttonClose.removeClass("-inActive")
    }else{
      this.$el.removeClass("-active")
      this.$buttonClose.addClass("-inActive")
      this.$buttonClose.removeClass("-active")
    }
  }

  mounted() {
    var self = this
    //フォーム
    this.$form = this.$el.find('form')
    //開くボタン
    this.$buttonSearch = this.$el.find('.buttonSearch')
    this.$buttonSearch.on('click', ()=>{
      this.$data.active = true
    })
    //閉じるボタン
    this.$buttonClose = this.$el.find('.buttonClose')
    this.$buttonClose.on('click', ()=>{
      this.$data.active = false
    })
    //背景
    this.$bg = this.$el.find('.searchBox_bg')
    this.$bg.on('click', ()=>{
      this.$data.active = false
    })
    //フォーム
    this.searchForm = new SearchForm(this.$el.find('.searchBox_form').get(0))
  }
}

//export as singleton
const instance = new SearchBox("#searchBox")
// Object.seal(instance)
export default instance
