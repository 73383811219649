import windowScroll from '../../../../../js/assets/window_scroll.js'
import Component from "../../../../../js/assets/component.js"

class ButtonPageTop extends Component{
  constructor(selector) {
    //set root element
    super(selector)
  }

  switchStateActive(scrollTop) {
    if(scrollTop > 0){
      this.activate()
    }else{
      this.inActivate()
    }
  }

  activate() {
    this.$el.addClass("active")
  }

  inActivate() {
    this.$el.removeClass("active")
  }

  //can access this.el or this.$el
  mounted() {
    var self = this

    windowScroll.addEventListener("scrollTop", (data) => {
      var scrollTop = data.scrollTop
      self.switchStateActive(scrollTop)
    });

    self.$el.on("click", function(){
      windowScroll.scrollTo({
        duration: 1200,
        easing: "easeInOutQuart"
      });
      return false;
    })
  }
}

//export as singleton
const instance = new ButtonPageTop("#buttonPageTop")
// Object.seal(instance)
export default instance
