import Header from '../01-components/Templates/organisms/common_block/header.js'
import WindowScroll from '../js/assets/window_scroll.js'
import Device from '../js/assets/device.js'
import PageTop from '../01-components/Templates/atoms/button/button_pagetop/button_pagetop.js'
import MenuSP from '../01-components/Templates/organisms/common_block/menu_sp.js'
import SearchBox from '../01-components/Templates/organisms/search_box/search_box.js'
import ButtonFavorite from '../01-components/Templates/atoms/button/button_favorite.js'

document.addEventListener('DOMContentLoaded', function(){

  let prevScrollTop = WindowScroll.scrollTop
  //サブメニューをスクロール可能に
  MenuSP.addEventListener("active", (data) => {
    if (data === true) {
      WindowScroll.preventScrollingBg()
    } else {
      WindowScroll.allowScrollingBg()
    }
  })

  //検索ボックスをスクロール可能に
  SearchBox.addEventListener("active", (data) => {
    if (data === true) {
      WindowScroll.preventScrollingBg()
    } else {
      WindowScroll.allowScrollingBg()
    }
  })

  // お気に入り登録ボタン
  $('.buttonFavorite_root').each(function(){
    new ButtonFavorite($(this))
  })
});
