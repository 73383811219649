import Util from '../../../../js/assets/util.js'
import Component from '../../../../js/assets/component.js'
import DestinationSelector from './destination_selector.js'

class SearchForm extends Component {
  constructor(selector) {
    //set root element
    super(selector)
  }

  // define data
  data() {
    return {
      "validated": false
    }
  }

  // action at data changed
  changed() {
    return {
      "validated": (val) => {
        if(val === true){
          this.$form.submit()
        }
      }
    }
  }

  clearCondition(){
    //出発地
    this.$departureAirport.find('option[selected="selected"]').removeAttr('selected')
    //出発日
    // this.$datePicker.datepicker("setDate",null)
    this.$datePicker.attr('value', '')
    //行き先
    this.destinationSelector.initSelect()
    //旅行日数
    this.$period.each(function(){
      $(this).removeAttr('checked')
    })
    //航空会社
    this.$airline.find('option[selected="selected"]').removeAttr('selected')
    //価格帯
    this.$price.find('option[selected="selected"]').removeAttr('selected')
    //座席クラス
    this.$seatingClass.each(function(){
      $(this).removeAttr('checked')
    })
    //ツアーコード
    this.$tourCode.attr('value', '')
    //キーワード
    this.$inputKeyword.attr('value', '')

    //イベント発火
    this.dispatchEvent('condition_cleared')
  }

  validate(){
    //出発日
    var departure_date = Util.sanitize(this.$datePicker.val())
    this.$datePicker.val(departure_date)

    //キーワード入力欄
    var keyword = Util.sanitize(this.$inputKeyword.val())
    this.$inputKeyword.val(keyword)

    this.$data.validated = true
  }

  mounted() {
    var self = this
    //フォーム
    this.$form = this.$el.find('form')
    //出発地
    this.$departureAirport = this.$el.find('[name="departure_airport"]')
    //出発日
    $.datepicker.setDefaults( $.datepicker.regional[ "ja" ] )
    this.$datePicker = this.$el.find('.datepicker')
    this.$datePicker.datepicker({
      dateFormat: 'yy/mm/dd'
    })
    this.$datePicker.attr({
      id: this.$datePicker.attr("id") + Util.createGUID(),
      autocomplete: "off"
    })
    this.$datePicker.removeClass("hasDatepicker").off("focusin").on("focusin", function(e) {
      $(this).datepicker();
    });
    //行き先選択
    this.destinationSelector = new DestinationSelector(this.el.querySelector('.destination'))
    //旅行日数
    this.$period = this.$el.find('[name="period[]"]')
    //航空会社
    this.$airline = this.$el.find('[name="airline"]')
    //価格帯
    this.$price = this.$el.find('[name="price"]')
    //座席クラス
    this.$seatingClass = this.$el.find('[name="seating_class[]"]')
    //ツアーコード
    this.$tourCode = this.$el.find('[name="tour_code"]')
    //キーワード入力欄
    this.$inputKeyword = this.$el.find('[name="name"]')


    //クリアボタン
    this.createEvent('condition_cleared')
    this.$buttonClear = this.$el.find('input[type="reset"]')
    this.$buttonClear.on('click', ()=>{
      this.clearCondition()
    })
    //送信ボタン
    this.$buttonSubmit = this.$el.find('button[type="submit"]')
    this.$buttonSubmit.on('click', ()=>{
      this.validate()
      return false
    })
  }

}

export default SearchForm

