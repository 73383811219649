import Observer from "./observer.js"

class Component extends Observer {
  constructor(selector) {
    super()

    // this.store = store

    if(typeof selector === "String" || typeof selector === "string"){
      this.selector = selector
      this.init()
    }else if(this.root() && (typeof this.root() === "String" || typeof this.root() === "string")){
      this.$el = $(this.root())
      this.el = this.$el.get(0)
      if(this.$el.length===0){
        this.clearData()
        return
      }
      this.mounted()
    }else{
      if(selector instanceof jQuery){
        this.el = selector.get(0)
      }else{
        this.el = selector
      }
      this.$el = $(this.el)

      if(this.$el.length===0){
        this.clearData()
        return
      }
      this.mounted()
    }
  }
  async init() {
    this.el = await this.getElement(this.selector)
    this.$el = $(this.el)
    if(this.$el.length===0){
      this.clearData()
      return
    }
    this.mounted()
  }
  root(){

  }
  mounted() {

  }
  async getElement(selector) {
    var el

    try{
      el = document.querySelectorAll(selector)[0]
    }catch(e){

    }

    if(el){
      return el
    }else{
      return new Promise((resolve, reject)=> {
        document.addEventListener('DOMContentLoaded', function(){
          el = document.querySelectorAll(selector)[0]
          resolve(el)
        })
      })
    }
  }
  async animationOff(el){
    $(el).addClass("-animationOff")
    return new Promise((resolve, reject)=>{
      setTimeout(()=>{
        resolve()
      }, 10)
    })
  }
  async animationOn(el){
    return new Promise((resolve, reject)=>{
      setTimeout(()=>{
        $(el).removeClass("-animationOff")
        resolve()
      }, 10)
    })
  }
  async wait(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }
}

export default Component
