import Component from "../../../../js/assets/component.js"
import SubMenu from "../../organisms/common_block/submenu_pc.js"

class MenuPC extends Component {
  constructor(el){
    super(el)
  }

  data() {
    return {
      "activeSubMenuIndex": null,
      "showSubMenuFlag": false
    }
  }

  changed() {
    return {
      "activeSubMenuIndex": (index, prev) => {
        if(!this.$data.showSubMenuFlag){
          return
        }
        SubMenu.showSubMenu(index)
        this.activateGlobalMenu(index)
      },
      "showSubMenuFlag": (value, prev)=>{
        if(value===true){
          SubMenu.showSubMenu(this.$data.activeSubMenuIndex)
          this.activateGlobalMenu(this.$data.activeSubMenuIndex)
        }else{
          SubMenu.showSubMenu(null)
          this.activateGlobalMenu(null)
        }
      }
    }
  }

  activateGlobalMenu(index){
    for(let i=0; i<this.globalMenu.length; i++){
      if(i===index){
        this.globalMenu[i].activate()
      }else{
        this.globalMenu[i].inActivate()
      }
    }
  }

  mounted() {
    var self = this
    this.globalMenu = []
    this.$el.find("#globalMenu-pc li.primaryNav_item").each(function(i){
      self.globalMenu[i] = new MenuItem($(this).get(0), i)
    })

    for(let i=0; i < this.globalMenu.length; i++){
      this.globalMenu[i].addEventListener("active", (i)=>{
        this.$data.activeSubMenuIndex = i
      })
    }

    this.showSubMenuTimer
    this.$el.on("mouseenter", ()=>{
      this.showSubMenuTimer = setTimeout(()=>{
        this.$data.showSubMenuFlag = true
      }, 300)
    })
    this.$el.on("mouseleave", ()=>{
      clearTimeout(this.showSubMenuTimer)
      this.$data.showSubMenuFlag = false
      this.$data.activeSubMenuIndex = null
    })
  }
}

class MenuItem extends Component {
  constructor(el, index){
    super(el)
    this.index = index
  }

  activate(){
    this.$el.addClass("-active")
  }

  inActivate(){
    this.$el.removeClass("-active")
  }

  mounted(){
    this.rollOverTimer
    this.createEvent("active")
    //activeにするまで間隔をあける
    this.$el.on("mouseenter", ()=>{
      this.rollOverTimer = setTimeout(()=>{
        this.dispatchEvent("active", this.index)
      }, 100)
    })
    this.$el.on("mouseleave", ()=>{
      clearTimeout(this.rollOverTimer)
    })
  }
}

const instance = new MenuPC("#menu")
export default instance
