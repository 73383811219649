import EventDispatcher from './event_dispatcher.js'
import Util from './util.js'

class Observer extends EventDispatcher {
  constructor() {
    super()
    //
    this._data = {}
    this._noChange = {}
    for(const key in this.data()){
      this._data[key] = this.data()[key]
      this._noChange[key] = false
      this.recordData(key, this._data[key])
    }
  }

  recordData(key, val) {
    var self = this
    self._data["_"+key] = val

    if(self.changed()[key]){
      self.addEventListener(""+key, self.changed()[key])
    }

    Object.defineProperty(self._data, key, {
      set: function(val){
        if(self._noChange[key]===true){
          return
        }
        var prev = self._data["_"+key]
        if(self._data["_"+key]===val){
          return
        }
        self._data["_"+key] = val
        self.dispatchEvent(""+key,
          self._data["_"+key],
          prev
        )
      },
      get: function(){
        return self._data["_"+key]
      }
    })
  }

  data() {
    return {

    }
  }

  changed() {
    return {
      "hoge": function(val,prev){

      }
    }
  }

  get $data() {
    return this._data
  }

  noChange(key){
    if(this._noChange[key] !== undefined){
      this._noChange[key] = true
    }
  }

  canChange(key){
    if(this._noChange[key] !== undefined){
      this._noChange[key] = false
    }
  }

  clearData(){
    //Observerを継承したクラス（Component等）で意図せずdataの処理が走ってしまわないように削除する
    for(const key in this.data()){
      if (this.changed()[key]) {
        // console.log("hoge")
        // this._data[key] = this.data()[key]
        this.removeEventListener(""+key, this.changed()[key])
      }
    }
  }
}

export default Observer
