import Component from "../../../../js/assets/component.js"
import MenuPC from "../../organisms/common_block/menu_pc.js"
// import MenuSP from "../../organisms/common_block/menu_sp.js"
// import SearchBox from "../../organisms/search_box/search_box.js"

class Header extends Component {
  constructor(el){
    super(el)
  }

  mounted() {

  }
}

const header = new Header("#header")
export default header
