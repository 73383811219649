import Component from "../../../../js/assets/component.js"
import SubMenuSPContainer from "./submenu_sp_container.js"

class SubMenuSP extends Component {
  constructor(el) {
    super(el)
  }

  data() {
    return {
      "active": false,
      "activeIndex": null
    }
  }

  changed() {
    return {
      "active": (val, prev) => {
        if (this.$data.activeIndex >= this.subMenuSPContainer.length) {
          this.$data.activeIndex = null
          return
        }
        if (val === true) {
          this.activate()
        } else {
          this.inActivate()
        }
      },
      "activeIndex": (index, prev) => {
        if (index === null || index >= this.subMenuSPContainer.length) {
          return
        }
        this.activateSubMenuContainer(index)
      }
    }
  }

  activate() {
    this.$el.addClass("-active")
  }

  inActivate() {
    this.$el.removeClass("-active")
  }

  activateSubMenuContainer(index) {
    for (let i = 0; i < this.subMenuSPContainer.length; i++) {
      if (i === index) {
        this.subMenuSPContainer[i].initToggle()
        this.subMenuSPContainer[i].$data.active = true
      } else {
        this.subMenuSPContainer[i].$data.active = false
      }
    }
  }

  init() {
    this.$data.activeIndex = null
  }

  mounted() {
    var self = this

    //戻るボタン
    this.$buttonBackToMenu = this.$el.find(".buttonBackToMenu-sp")
    this.$buttonBackToMenu.on("click", () => {
      this.$data.activeIndex = null
    })

    //各コンテナ
    this.subMenuSPContainer = []
    this.$el.find(".subMenuContainer").each(function () {
      self.subMenuSPContainer.push(new SubMenuSPContainer($(this).get(0)))
    })
  }
}

export default SubMenuSP
