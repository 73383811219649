class Util {
  constructor() {
    this.win
  }

  static createGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c){
      var r = Math.random()*16|0,
         v = c == "x" ? r : (r&0x3|0x8);
      return v.toString(16);
    }).toUpperCase();
  }

  static sanitize(str) {
    var sanitizedStr = ''
    if(!str){
      return
    }
    sanitizedStr = str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')
    return sanitizedStr
  }

  static popupWindow(url, width, height){
    if (!this.win) {
    }else{
      this.win.close()
    }
    let posLeft = screen.width/2 - width/2
    let posTop = screen.height/2 - height/2
    this.win = window.open(url, 'popup1', `width=${width}, height=${height}, menubar=no, toolbar=no, scrollbars=yes, left=${posLeft}, top=${posTop}`)

    // this.win.opener = null
    return this.win
  }

  // window.alertの代替
  // Google Chromeにて、window.alert()の挙動がおかしかったので作成
  static alert(message, title="アラート") {
    let $dialog = $('#dialog-message')

    // 無ければ挿入。初回など
    if ($dialog.length === 0) {
      $dialog = $('<div id="dialog-message" title><p id="dialog-message-text"></p></div>')
      $('body').append($dialog)
    }

    $dialog.find('#dialog-message-text').text(message)
    $dialog.attr('title', title)

    $dialog.dialog({
      modal: true,
      buttons: {
        OK: function() {
          $( this ).dialog( "close" )
        }
      }
    });
  }
}

export default Util
