import Component from '../../../../js/assets/component.js'

class DestinationSelector extends Component {
  constructor(selector) {
    //set root element
    super(selector)
  }

  // data(){
  //   return {
  //     "selectedArea": null,
  //     "selectedCountry": null,
  //     "selectedCity": null
  //   }
  // }

  // changed(){
  //   return {
  //     "selectedArea": (val, prev)=>{
  //       this.changeCountrySelectBox(val, prev)
  //     },
  //     "selectedCountry": (val, prev)=>{
  //       this.changeCitySelectBox(val, prev)
  //     },
  //     "selectedCity": (val, prev)=>{

  //     }
  //   }
  // }

  updateSelect(targetCode, child_cls, reserve_id, key, targetCode_sub, sub_key){
    $(child_cls).children("option").each(function(){$(this).remove();});
    if(targetCode === "" || targetCode === undefined){
      targetCode = targetCode_sub;
      key = sub_key;
    }
    if(targetCode === "" || targetCode === undefined){
      $(reserve_id).first().children("option").each(
        function(){
          $(child_cls).append($(this).clone());
        }
      );
      $(child_cls).val("");
      return;
    }
    var first = true;
    $(reserve_id).first().children("option").each(function () {
      var code = $(this).data(key);
      if (targetCode == code||code === undefined) {
          $(child_cls).append($(this).clone());
          if(first/* && code === undefined*/){
            $(child_cls).val($(this).val());
            first = false;
          }
      }
    });
  }

  initSelect(){
    this.$areaSelectBox.find('option[selected="selected"]').removeAttr('selected')
    setTimeout(()=>{
      this.$areaSelectBox.change()
    },10)
  }

  mounted(){
    var self = this

    this.$areaSelectBox = this.$el.find('[name="destination_area"]')
    this.$countrySelectBox = this.$el.find('[name="destination_country"]')
    this.$citySelectBox = this.$el.find('[name="destination_city"]')


    $(".searchBox_destination_area").on('change',function(event, element){
      $(".searchBox_destination_area").val($(this).val());
      self.updateSelect(
        $(this).val(),
        ".searchBox_destination_country",
        "#preserveCountryOption",
        "area_id"
      );
      self.updateSelect(
        $(".searchBox_destination_country").val(),
        ".searchBox_destination_city",
        "#preserveCityOption",
        "country_id",
        $("#destination_area").val(),
        "area_id"
      );
      return false;
    });
    $(".searchBox_destination_country").on('change',function(event, element){
      $(".searchBox_destination_country").val($(this).val());
      self.updateSelect(
        $(this).val(),
        ".searchBox_destination_city",
        "#preserveCityOption",
        "country_id",
        $("#destination_area").val(),
        "area_id"
      );
      return false;
    });
    if($("#preserveCountryOption").first().children("option").length===0){
      $("#destination_country").first().children("option").each(function(){
        $("#preserveCountryOption").first().append( $(this).clone() );
      });
    }
    if($("#preserveCityOption").first().children("option").length===0){
      $("#destination_city").first().children("option").each(function(){
        $("#preserveCityOption").first().append( $(this).clone() );
      });
    }
    this.updateSelect(
      $("#destination_area").val(),
      ".searchBox_destination_country",
      "#preserveCountryOption",
      "area_id"
    );
    this.updateSelect(
      $("#destination_country").val(),
      ".searchBox_destination_city",
      "#preserveCityOption",
      "country_id",
      $("#destination_area").val(),
      "area_id"
    );
  }

}

export default DestinationSelector
