import EventDispatcher from "./event_dispatcher.js"

class WindowScroll extends EventDispatcher {
  constructor() {
    super();
    var self = this;
    self.scrollTop = 0;
    self.prevScrollTop = 0;
    window.addEventListener("scroll", function(e) {
      self.scrollTop = window.scrollY || window.pageYOffset
    });
  }

  get scrollTop() {
    return this._scrollTop
  }

  set scrollTop(val) {
    if(this._scrollTop !== val){
      this._scrollTop = val
      this.dispatchEvent("scrollTop", {
        scrollTop: this._scrollTop
      })
    }
  }

  get prevScrollTop() {
    return this._prevScrollTop
  }

  set prevScrollTop(scrollTop){
    if(this._prevScrollTop !== scrollTop){
      this._prevScrollTop = scrollTop
    }
  }

  async scrollTo (props) {
    return new Promise((resolve, reject)=> {
      $("html, body")
        .stop(false,false)
        .animate({
          scrollTop: props.scrollTop?props.scrollTop:0
        }, {
          duration: props.duration?props.duration:1500,
          easing: props.easing?props.easing:"linear",
          queue: false,
          complete: function(){
            resolve()
          }
        })
      })
  }

  cancelScroll () {
    $("html, body").stop(false,false);
  }

  preventScrollingBg(){
    this.prevScrollTop = this.scrollTop
    $("#container").addClass("-fixed")
    $("#container").css({
      "top": -1 * this.prevScrollTop
    })
    window.scrollTo(0, 0)
  }

  allowScrollingBg(){
    $("#container").removeClass("-fixed")
    $("#container").css({
      "top": 0
    })
    window.scrollTo(0, this.prevScrollTop)
  }
}

//export as singleton and prohibit addind new property
const instance = new WindowScroll()
Object.seal(instance)
export default instance
