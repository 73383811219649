import Component from "../../../../js/assets/component.js"
// import ButtonSubMenuToggle from "./button_submenu_toggle.js"

class SubMenuSPContainer extends Component {
  constructor(el) {
    super(el)
  }

  data() {
    return {
      "active": false
    }
  }

  changed() {
    return {
      "active": (val, prev) => {
        if (val === true) {
          this.activate()
        } else {
          this.inActivate()
        }
      }
    }
  }

  activate() {
    this.$el.addClass("-active")
  }

  inActivate() {
    this.$el.removeClass("-active")
  }

  async initToggle() {
    // for (let i = 0; i < this.buttonToggle.length; i++) {
    //   await this.buttonToggle[i].init()
    // }
  }

  mounted() {
    var self = this

    // this.buttonToggle = []
    // this.$el.find(".subMenuItem-02.-toggle").each(function () {
    //   self.buttonToggle.push(new ButtonSubMenuToggle($(this).get(0)))
    // })

  }
}

export default SubMenuSPContainer
