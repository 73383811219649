import Observer from "./observer.js"

class Device extends Observer {
  constructor() {
    super();

    this.BREAK_POINT_SP = 750
    this.DEVICE_MODE_SP = "sp"
    this.DEVICE_MODE_TB = "tb"
    this.DEVICE_MODE_PC = "pc"

    this.switchDeviceMode()
    window.addEventListener("resize", ()=>{
      this.switchDeviceMode()
    })
  }

  data(){
    return {
      "device_mode": null
    }
  }

  changed(){
    return {
      "device_mode": (device, prev)=>{

      }
    }
  }

  switchDeviceMode(){
    if($(window).width() <= this.BREAK_POINT_SP){
      this.$data.device_mode = this.DEVICE_MODE_SP
    }else{
      this.$data.device_mode = this.DEVICE_MODE_PC
    }
  }

  static BREAK_POINT_SP(){
    return this.BREAK_POINT_SP
  }
  static DEVICE_MODE_SP(){
    return this.DEVICE_MODE_SP
  }
  static DEVICE_MODE_TB(){
    return this.DEVICE_MODE_TB
  }
  static DEVICE_MODE_PC(){
    return this.DEVICE_MODE_PC
  }
}

//export as singleton and prohibit addind new property
const instance = new Device()
// Object.seal(instance)
export default instance
