import Component from '../../../../js/assets/component.js'
import Util from '../../../../js/assets/util.js'

class ButtonFavorite extends Component {
  constructor(el){
    super(el)
  }

  data(){
    return {
      "product_id": null,
      "is_favorite": false,
      "add_url": "",
      "remove_url": "",
    }
  }

  changed(){
    return {
      "is_favorite": (val, prev)=>{
        if (val) {
          this.$el.removeClass('buttonFavorite')
          this.$el.addClass('buttonFavorite-added')
        } else {
          this.$el.removeClass('buttonFavorite-added')
          this.$el.addClass('buttonFavorite')
        }
      }
    }
  }

  addFavorite(){
    const self = this

    $.ajax({
      url: self.$data.add_url,
      type: 'POST',
      data: '',
      dataType: 'json',
      beforeSend: function(xhr, settings) {
          // Buttonを無効にする
          $('.buttonFavorite_root').prop('disabled', true);
      }
    }).done(function(data) {
      if (data.done) {
        self.$data.is_favorite = true
      } else {
        Util.alert('ログインしてください。')
      }
    }).fail(function(data) {
        Util.alert('お気に入りの追加に失敗しました。');
    }).always(function(data) {
        // Buttonを有効にする
        $('.buttonFavorite_root').prop('disabled', false);
    });
  }

  removeFavorite(){
    const self = this

    $.ajax({
      url: self.$data.remove_url,
      type: 'DELETE',
      data: '',
      dataType: 'json',
      beforeSend: function(xhr, settings) {
          // Buttonを無効にする
          $('.buttonFavorite_root').prop('disabled', true);
      }
    }).done(function(data) {
      if (data.done) {
        self.$data.is_favorite = false
      } else {
        Util.alert('ログインしてください。')
      }
    }).fail(function(data) {
      Util.alert('お気に入りの削除に失敗しました。');
    }).always(function(data) {
        // Buttonを有効にする
        $('.buttonFavorite_root').prop('disabled', false);
    });
  }

  mounted(){
    this.$data.product_id = this.$el.attr('data-productId')
    this.$data.is_favorite = this.$el.attr('data-isFavorite')
    this.$data.add_url = this.$el.attr('data-addUrl')
    this.$data.remove_url = this.$el.attr('data-removeUrl')

    this.$el.on('click', () => {
      if (this.$data.is_favorite) {
        this.removeFavorite()
      } else {
        this.addFavorite()
      }
    })
  }
}

export default ButtonFavorite
