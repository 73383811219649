import Component from "../../../../js/assets/component.js"
import SubMenuSP from "./submenu_sp.js"

class MenuSP extends Component {
  constructor(el) {
    super(el)
    this.$buttonMenu = null
    this.$buttonClose = null
    this.$bg = null
    this.$menuContainer = null
    this.$globalMenuItems = null
    this.globalMenuItem = []
    this.subMenu = null
  }

  data() {
    return {
      "active": false,
      "subMenuIndex": null
    }
  }

  changed() {
    return {
      "active": async (val, prev) => {
        if (val === true) {
          //メニューボタン
          this.$buttonMenu.removeClass("-active")
          this.$buttonMenu.addClass("-inActive")
          //閉じるボタン
          this.$buttonClose.removeClass("-inActive")
          this.$buttonClose.addClass("-active")
          //サブメニュー初期化
          this.subMenu.init()
          await this.containerAnimationOff()
          this.activate()
          await this.containerAnimationOn()
        } else {
          //メニューボタン
          this.$buttonMenu.removeClass("-inActive")
          this.$buttonMenu.addClass("-active")
          //閉じるボタン
          this.$buttonClose.removeClass("-active")
          this.$buttonClose.addClass("-inActive")
          this.inActivate()
        }
      },
      "subMenuIndex": (index, prev) => {
        if (index === null) {
          this.subMenu.$data.active = false
          this.$menuContainer.removeClass("-subMenu")
          this.$menuContainer.addClass("-globalMenu")
        } else {
          this.subMenu.$data.active = true
          this.$menuContainer.removeClass("-globalMenu")
          this.$menuContainer.addClass("-subMenu")
        }
      }
    }
  }

  activate() {
    this.$el.addClass("-active")
  }

  inActivate() {
    this.$el.removeClass("-active")
  }

  async containerAnimationOff() {
    this.$menuContainer.addClass("-animationOff")
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, 50)
    })
  }

  async containerAnimationOn() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.$menuContainer.removeClass("-animationOff")
        resolve()
      }, 50)
    })
  }

  mounted() {
    var self = this

    //buttonMenu
    this.$buttonMenu = this.$el.find(".buttonMenu")
    this.$buttonMenu.on("click", () => {
      this.$data.active = true
    })

    //buttonClose
    this.$buttonClose = this.$el.find(".buttonClose")
    this.$buttonClose.on("click", () => {
      this.$data.active = false
    })

    //bg
    this.$bg = this.$el.find(".bg")
    this.$bg.on("click", () => {
      this.$data.active = false
    })

    //this.$menuContainer
    this.$menuContainer = this.$el.find(".menuContainer")

    //this.$globalMenuItems
    this.$globalMenuItems = this.$el.find(".globalMenuItems-sp")
    this.$globalMenuItems.find(".globalMenuItem-sp a:not([href])").each(function (i) {
      self.globalMenuItem.push($(this))
      $(this).on("click", function () {
        self.subMenu.$data.activeIndex = i
      })
    })

    //subMenu
    this.subMenu = new SubMenuSP(this.$el.find(".subMenu").get(0))
    this.subMenu.addEventListener("activeIndex", (val) => {
      this.$data.subMenuIndex = val
    })
  }
}

const instance = new MenuSP("#menu-sp")
export default instance
