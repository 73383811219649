class EventDispatcher {
  constructor() {
    this.events = {}
  }

  createEvent(eventName) {
    if(this.events[eventName]){

    }else{
      this.events[eventName] = [];
    }
  }

  addEventListener(eventName, listener, index) {
    if(this.events[eventName]){

    }else{
      this.events[eventName] = []
    }

    if(index!==undefined && this.events[eventName][index]){
      this.events[eventName].splice(index, 0, listener)
    }else{
      this.events[eventName].push(listener)
    }
  }

  removeEventListener(eventName, listener) {
    if(this.events[eventName]){
      let length = this.events[eventName].length
      for(let i=0;i<length;i++){
        if(this.events[eventName][i]===listener){
          this.events[eventName].splice(i, 1)
        }
      }
    }
  }

  async dispatchEvent(eventName) {
    if(this.events[eventName]){
      let length = this.events[eventName].length
      var args = []
      for(let i =1; i < arguments.length; i++){
        args.push(arguments[i])
      }
      for(let i=0;i<length;i++){
        await this.events[eventName][i](...args)
      }
    }
  }
}

export default EventDispatcher;
